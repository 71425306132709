@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.gallery {
  position: relative;

  width: 100%;
  height: $vh100;

  &--z-over-all {
    z-index: $z-mega-over + 1;
  }

  &__cont {
    @include full-abs;
  }

  &__slider {
    @include full-abs;

    .swiper {
      @include full-abs;
    }
  }

  &__slide {
    @include full-abs;
  }

  &__slide-over {
    @include full-abs;

    pointer-events: none;

    background: rgba(#000710, 0.1);

    &:before,
    &:after {
      content: '';

      position: absolute;
      left: 0;
      right: 0;
    }

    &:before {
      top: 0;

      background: linear-gradient(180deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.4;

      height: rem(400);

      @include mmax(768) {
        height: rem(301);
      }
    }

    &:after {
      bottom: 0;

      height: rem(317);

      background: linear-gradient(180deg, #000205 0%, rgba(0, 10, 24, 0) 100%);
      opacity: 0.8;
      transform: matrix(1, 0, 0, -1, 0, 0);

      @include mmax(768) {
        height: rem(301);
      }
    }
  }

  &__img {
    @include full-rel;
    @include full-abs;
    object-fit: cover;
  }

  &__footer {
    position: absolute;
    z-index: 1;
    bottom: rem(60);
    left: rem(60);
    right: rem(60);

    @include flc;
    justify-content: space-between;
    align-items: flex-end;

    color: $c-white;
    pointer-events: none;

    @include mmax(768) {
      display: block;
      bottom: rem(22);
      left: rem($padding-hor-m);
      right: rem($padding-hor-m);

      text-align: center;
    }
  }

  &--subgallery &__footer {
    bottom: rem(120);
  }

  &__label {
    width: 40%;

    font-size: rem(50);
    font-weight: 700;
    line-height: (60/50);

    @include mmax(768) {
      font-size: rem(32);

      width: 100%;
    }
  }

  &__counter {
    @include mmax(768) {
      margin-top: rem(20);

      font-size: rem(14);
    }

    b {
      font-weight: 600;
      font-size: rem(32);

      @include mmax(768) {
        font-size: rem(24);
      }
    }
  }

  &__close {
    position: absolute;
    top: rem(32);
    right: rem(62);
    z-index: 1;

    @include reset-button;
    @include size(rem(76));

    background: $c-white;
    border-radius: 50%;

    @include mmax(768) {
      top: rem(16);
      right: rem(16);

      @include size(rem(50));
    }

    svg {
      width: rem(20);
      height: auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include reset-button;
    @include flc;
    @include size(rem(90));

    border-radius: 50%;
    background: $c-white;

    pointer-events: all;
    transition: all $time-fast;

    @include mmax(768) {
      bottom: rem(24);
      top: auto;

      @include size(rem(32));
    }

    &:after {
      content: '';

      position: absolute;
      top: rem(-6);
      bottom: rem(-6);
      left: rem(-6);
      right: rem(-6);

      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      transition: all $time-fast;

      @include mmax(768) {
        display: none;
      }
    }

    &:hover:after {
      transform: scale(1.1);
    }

    &:before {
      content: '';

      display: block;
      @include size(rem(24));

      @include mmax(768) {
        @include size(rem(20));
      }
    }

    &.swiper-button-disabled {
      opacity: 0.6;
    }
  }

  .swiper-button-prev {
    left: rem(60);

    @include mmax(768) {
      left: rem(24);
    }

    &:before {
      background: transparent url('./i/arr-prev.svg') center center no-repeat;
      background-size: contain;
    }
  }

  .swiper-button-next {
    right: rem(60);

    @include mmax(768) {
      right: rem(24);
    }

    &:before {
      background: transparent url('./i/arr-next.svg') center center no-repeat;
      background-size: contain;
    }
  }

  &--subgallery {
    .swiper-button-prev,
    .swiper-button-next {
      //display: none;
      @include mmax(768) {
        top: 50%;
      }
    }
  }
}
