@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.menu {
  @include full-fix;
  z-index: $z-alpha-over - 1;

  background: $c-accent;
  color: $c-white;
  opacity: 0;
  pointer-events: none;
  transition: all $time-fast;

  @include mmax(768) {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--open {
    opacity: 1;
    pointer-events: initial;
  }

  &:before {
    @include mmax(768) {
      content: '';

      position: fixed;
      left: 0;
      right: 0;
      z-index: 1;

      height: rem($header-h-m + 40);

      background: linear-gradient(to bottom, $c-accent 70%, rgba($c-accent, 0));
      pointer-events: none;
    }
  }

  &__cont {
    @include full-abs;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: rem($header-h + 60) rem($padding-hor) rem(60);

    @include mmax(768) {
      @include full-reset;
      position: relative;

      padding: rem($header-h-m + 40) rem($padding-hor-m) rem(24);
      min-height: $vh100init;
    }
  }

  &__bg {
    @include full-abs;

    pointer-events: none;

    img {
      position: absolute;
      bottom: 0;
      right: 0;

      width: rem(942);
      height: auto;

      @include mmax(768) {
        left: rem(-98);
        right: auto;

        height: rem(500);
        width: auto;
        max-width: none;
      }
    }
  }


  &__nav {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto 0;

    height: rem(1080 - 500);

    @include mmax(768) {
      display: block;

      height: auto;
    }
  }

  &__section {
    width: percentage(1/3);

    @include mmax(768) {
      width: 100%;
      
      @include list-el {
        margin-bottom: rem(35);
      }
    }
  }

  &__section-main {
    font-weight: 600;
    font-size: rem(32);

    @include mmax(768) {
      font-size: rem(24);
    }
  }

  &__section-sub {
    margin-top: rem(44);

    @include mmax(768) {
      margin-top: rem(16);
    }
  }

  &__section-sub-item {
    @include flc;
    justify-content: flex-start;
    
    font-size: rem(24);

    @include mmax(768) {
      font-size: rem(16);
    }

    &:before {
      content: '';
      @include size(rem(12));
      display: block;
      margin-right: rem(16);

      background: url("./i/menu-item.svg") center center no-repeat;
      background-size: contain;
    }
    
    @include list-el {
      margin-bottom: rem(26);

      @include mmax(768) {
        margin-bottom: rem(12);
      }
    }
  }

  &__item {
    font-weight: inherit;
    color: inherit;
    transition: all $time-fast;

    &:hover {
      color: $c-black;
    }
  }

  &__footer {
    position: relative;

    width: 100%;

    @include mmax(768) {
      margin-top: rem(60);
    }
  }
}