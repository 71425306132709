@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.floor-view {
  position: relative;

  height: $vh100;
  width: 100%;

  @include mmax(768) {
    height: auto;
  }

  &__cont {
    @include full-abs;
    @include flc;
    justify-content: space-between;

    padding: rem(160) rem($padding-hor) rem(105);

    @include mmax(768) {
      @include full-reset;

      display: block;
      padding: rem(85) rem($padding-hor-m) rem(80);
    }
  }

  &__info {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    width: rem(362);
    height: 100%;

    @include mmax(768) {
      display: block;
    }
  }

  &__info-top {

  }

  &__info-back {

  }

  &__info-flat {
    margin-top: rem(20);

    @include mmax(768) {
      display: none;
    }
  }

  &__info-bottom {
    @include mmax(768) {
      display: none;
    }
  }

  &__info-legend {

  }

  &__plan {
    width: rem(760);
    height: 100%;

    @include flc;

    @include mmax(768) {
      overflow-x: auto;
      overflow-y: hidden;

      display: block;
      margin: 0 rem($padding-hor-m * -1);
      padding: 0 rem($padding-hor-m);

      width: auto;
    }
  }

  &__nav {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: rem(362);
    height: 100%;

    @include mmax(768) {
      @include flc;
      justify-content: space-between;
      margin-top: rem(30);

      width: 100%;
    }
  }

  &__nav-compass {
    font-size: 0;

    @include mmax(768) {
      margin-top: rem(20);
    }

    svg {
      @include size(rem(86));
    }
  }

  &__nav-buttons {
    @include mmax(768) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      height: rem(60);
    }
  }

  &__nav-mini {

  }
}
