@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.teams {
  &__cont {

  }

  &__nav {
    @include mmax(768) {
      overflow-x: auto;
      overflow-y: hidden;

      margin: 0 rem($padding-hor-m * -1);
      padding: 0 rem($padding-hor-m);
    }
  }

  &__nav-cont {
    @include flc;
    justify-content: flex-start;
    padding: rem(8);

    height: rem(80);

    border-radius: rem(80);
    background: $c-white;

    @include mmax(768) {
      padding: rem(4);

      width: max-content;
      min-width: 100%;
      height: rem(50);
    }
  }

  &__nav-el {
    width: 25%;
    height: rem(64);

    border-radius: rem(80);
    transition: all $time-fast;
    color: $c-gray;

    @include mmax(768) {
      padding: 0 rem(32);

      width: auto;
      min-width: rem(220);
      height: rem(42);

      font-size: rem(16);
      white-space: nowrap;
    }

    &:hover:not(&--active) {
      background: #f2f6fa;
      color: $c-black;
    }

    &--active {
      color: $c-white;
      background: $c-accent;
    }
  }

  &__current {
    @include flc;
    justify-content: space-between;
    padding: rem(40) rem(60);
    margin-top: rem(60);

    border-radius: rem(80);
    background: $c-white;

    @include mmax(768) {
      display: block;
      padding: rem(20) rem($padding-hor-m);
      margin: rem(36) rem($padding-hor-m * -1) 0;

      border-radius: rem(32);
    }
  }

  &__media {

  }

  &__logo {
    img {
      object-fit: contain;
      @include size(rem(340));
      width: rem(340);
      height: auto;
      max-height: rem(340);

      @include mmax(768) {
        width: 100%;
        height: auto;
        max-height: none;
      }
    }
  }

  &__text {
    margin-right: rem(80);

    width: rem(432);

    @include mmax(768) {
      margin: rem(20) 0 0 0;

      width: auto;
    }
  }

  &__title {
    font-size: rem(32);
    font-weight: 600;
    line-height: (36/32);

    @include mmax(768) {
      font-size: rem(24);
    }
  }

  &__body {
    margin-top: rem(24);
    
    color: $c-gray;

    @include mmax(768) {
      margin-top: rem(18);
      margin-bottom: rem(40);
    }
  }
}