@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.button {
  --button-color: #{$c-accent};
  @include flc;
  display: inline-flex;
  padding: 0 rem(16);

  height: rem(60);
  min-width: rem(346);

  border-radius: rem(30);
  background: $c-white;
  color: var(--button-color, #{$c-accent});

  @include mmax(768) {
    height: rem(50);
  }

  &--m-i-only {
    @include mmax(768) {
      padding: 0;

      @include size(rem(50));
      min-width: rem(50);

      border-radius: 50%;
    }
  }

  &__cont {
    @include flc;
  }

  &__content {
    padding-top: rem(4);

    font-size: rem(16);
    text-transform: uppercase;
    font-weight: 900;
  }

  &--m-i-only &__content {
    @include mmax(768) {
      display: none;
    }
  }

  &__icon {
    @include flc;
    margin-left: rem(16);

    svg {
      width: rem(20);
      height: auto;
    }

    path[stroke] {
      stroke: var(--button-color, #{$c-accent})
    }

    path[fill] {
      fill: var(--button-color, #{$c-accent})
    }
  }

  &--m-i-only &__icon {
    @include mmax(768) {
      margin: 0;
    }
  }
}
