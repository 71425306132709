@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.window-pano {
  position: relative;

  width: 100%;
  height: $vh100;

  &__cont {
    @include full-rel;
  }

  &__main {
    @include full-rel;

    @include mmax(768) {
      padding-bottom: rem(59);
    }
  }

  &__pano {
    @include full-rel;
  }

  &__over {
    @include full-abs;

    background: rgba($c-black, 0.1);
    pointer-events: none;

    &:before,
    &:after {
      content: '';

      position: absolute;
      left: 0;
      right: 0;
    }

    &:before {
      top: 0;

      height: rem(270);

      background: linear-gradient(180deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.4;
    }

    &:after {
      bottom: 0;

      height: rem(400);

      background: linear-gradient(180deg, #000710 0%, rgba(0, 7, 16, 0) 100%);
      opacity: 0.6;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: rem($padding-hor);
    z-index: 10;

    @include flc;

    @include mmax(768) {
      bottom: 0;
      left: 0;
      right: 0;
      top: auto;

      height: rem(60);

      background: $c-white;
    }
  }
}
