@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.gallery-albums {
  position: relative;
  height: $vh100;
  width: 100%;

  &__cont {
    @include full-abs;
  }

  &__sliders {
    @include full-abs;
  }

  &__slider {
    @include full-abs;

    opacity: 0;
    pointer-events: none;
    transition: all 500ms;

    &--show {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__nav {
    position: absolute;
    bottom: rem(40);
    right: rem(144);

    width: rem(594);

    @include mmax(768) {
      right: rem(16);
      left: rem(16);
      bottom: rem(24);

      width: auto;
    }
  }

  &__nav-slider {
    .swiper-button-prev,
    .swiper-button-next {
      @include reset-button;
      @include flc;
      @include size(rem(60));

      top: 50%;
      transform: translate(0, -50%);

      border-radius: 50%;
      background: $c-white;

      pointer-events: all;
      transition: all $time-fast;

      @include mmax(768) {
        top: rem(24);

        @include size(rem(32));
      }

      &:after {
        display: none;
      }

      &:before {
        content: '';

        display: block;
        @include size(rem(24));

        @include mmax(768) {
          @include size(rem(20));
        }
      }

      &.swiper-button-disabled {
        opacity: 0.6;
      }
    }

    .swiper-button-prev {
      left: rem(20);

      @include mmax(768) {
        left: rem(8 + 2);
        bottom: rem(8 + 2);
      }

      &:before {
        background: transparent url('./i/arr-prev.svg') center center no-repeat;
        background-size: contain;
      }
    }

    .swiper-button-next {
      right: rem(20);

      @include mmax(768) {
        right: rem(8 + 2);
        bottom: rem(8 + 2);
      }

      &:before {
        background: transparent url('./i/arr-next.svg') center center no-repeat;
        background-size: contain;
      }
    }
  }

  &__nav-slide {
    position: relative;

  }

  &__nav-img-wrap {
    position: relative;
    overflow: hidden;

    @include prop(360, 200);

    border-radius: rem(20);

    &:after {
      content: '';

      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      top: 30%;

      background: linear-gradient(180deg, #000205 0%, rgba(0, 10, 24, 0) 100%);
      opacity: 0.8;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__nav-img {
    @include full-rel;
    @include full-abs;
    object-fit: cover;
  }

  &__nav-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 rem(24) rem(12);

    font-size: rem(18);
    font-weight: 600;

    color: $c-white;
    pointer-events: none;
  }

}