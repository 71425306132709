@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.home-hotspot {
  display: block;

  &__cont {
    display: block;
    position: relative;
  }

  &__icon {
    display: block;
    position: relative;

    &:before {
      content: '';

      position: absolute;
      top: rem(-6);
      left: rem(-6);
      right: rem(-6);
      bottom: rem(-6);
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      opacity: 0;
      transition: all 500ms;
    }
  }

  &:hover &__icon:before {
    opacity: 1;
  }

  &__icon-wrap {
    position: relative;

    @include size(rem(80));
    @include flc;

    background: $c-white;
    border-radius: 50%;
    transition: all 500ms;

    svg {
      @include size(rem(24));
    }

    @include mmax(768) {
      @include size(rem(60));
    }
  }

  &:hover &__icon-wrap {
    @include size(rem(90));

    @include mmax(768) {
      @include size(rem(70));
    }
  }

  &__text {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(rem(0), -50%);

    color: $c-white;
    pointer-events: none;
    opacity: 0;
    transition: all 500ms;

    @include mmax(768) {
      display: none;
    }
  }

  &:hover &__text {
    transform: translate(rem(24), -50%);
    opacity: 1;
  }

  &__label {
    display: block;
    white-space: nowrap;

    font-weight: 600;
    font-size: rem(32);
  }

  &__sublabel {
    display: block;
    white-space: nowrap;

    font-weight: 600;
  }
}