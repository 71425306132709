@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.footer {
  pointer-events: none;

  &__cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include mmax(768) {
      display: block;
    }
  }

  &__social {
    @include flc;
  }

  &__social-el {
    @include list-el {
      margin-right: rem(12);
    }

    @include size(rem(60));
    @include flc;

    border-radius: 50%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.5);
    transition: all $time-fast;
    pointer-events: initial;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    svg {
      @include size(rem(20));
    }
  }

  &--dark &__social-el {
    background: $c-white;

    svg {
      * {
        fill: #A5ADB9;
      }
    }
  }

  &__copy {
    font-size: rem(16);

    color: $c-white;
    pointer-events: initial;

    @include mmax(768) {
      margin-top: rem(21);

      text-align: center;
    }
  }

  &--dark &__copy {
    color: $c-gray;
  }
}