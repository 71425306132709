@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.floor-nav {
  position: relative;

  width: rem(60);
  height: rem(264);

  background: $c-white;
  border-radius: rem(30);

  @include mmax(768) {
    @include full-abs;

    height: auto;
    width: auto;

    background: $c-white;
    border-radius: 0;
  }

  &__cont {
    @include full-rel;
    @include flc;

    @include mmax(768) {
      justify-content: space-between;
      padding: 0 rem($padding-hor-m);
    }
  }

  &__current {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    @include mmax(768) {
      flex-direction: row;
    }
  }

  &__current-v {
    font-size: rem(32);
    font-weight: 600;
    line-height: 1;

    @include mmax(768) {
      margin-right: rem(10);

      font-size: rem(24);
    }
  }

  &__current-l {
    font-size: rem(16);

    color: $c-gray;
  }

  &__buttons {
    @include mmax(768) {
      @include flc;
    }
  }

  &__button {
    position: absolute;
    left: rem(20);

    @include mmax(768) {
      position: relative;
      left: auto;

      border: 1px solid rgba($c-accent, 0.2);
      margin-left: rem(10);

      @include size(rem(44));

      border-radius: 50%;
    }

    &--next {
      top: rem(20);

      @include mmax(768) {
        top: auto;
      }
    }

    &--prev {
      bottom: rem(20);

      @include mmax(768) {
        bottom: auto;
      }
    }

    svg {
      @include size(rem(20));
    }
  }
}
