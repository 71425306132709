@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.home {
  &__cont {
    position: relative;
  }

  &__pano {
    position: relative;
    width: 100%;
    height: $vh100;
    pointer-events: none;
  }

  &--finished &__pano {
    pointer-events: initial;
  }

  &__pano > div > canvas + div {
    &:before,
    &:after {
      content: '';

      position: absolute;
      left: 0;
      right: 0;

      height: rem(400);

      pointer-events: none;

      @include mmax(768) {
        height: rem(300);
      }
    }

    &:before {
      top: 0;
      background: linear-gradient(180deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.4;
    }

    &:after {
      bottom: 0;
      background: linear-gradient(180deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.4;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__start {
    position: absolute;
    bottom: rem(60);
    left: 0;
    right: 0;
    z-index: 10;

    @include flc;

    transition: all 500ms;
    pointer-events: none;

    @include mmax(786) {
      bottom: rem(24);

      padding: 0 rem($padding-hor-m);
    }

    .button {
      pointer-events: all;

      @include mmax(768) {
        width: 100%;
      }
    }
  }

  &--started &__start {
    opacity: 0;
    pointer-events: none;
  }

  &__pano-hp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    pointer-events: none;

    transition: all 500ms;
  }

  &--finished &__pano-hp {
    opacity: 1;
    pointer-events: initial;
  }

  .gg_contextmenu {
    display: none !important;
    pointer-events: none !important;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;

    padding: 0 rem($padding-hor) rem(60);
  }

  &__map {
    position: fixed;
    right: rem($padding-hor);
    bottom: rem(140);

    pointer-events: none;
    transition: all 500ms;
    opacity: 0;

    @include mmax(786) {
      right: rem(20);
      bottom: rem(24);
    }

    img {
      width: rem(164);
      height: auto;
    }
  }

  &--finished &__map {
    opacity: 1;
  }
}