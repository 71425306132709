@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.pl {
  position: relative;

  width: 100%;
  min-height: $vh100;

  &__cont {

  }

  &__bg {
    @include full-abs;
  }

  &__loader {

  }

  &__content {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: rem($header-h + 80);

    width: 100%;
    min-height: $vh100;

    @include mmax(768) {
      padding: rem($header-h-m + 34) rem($padding-hor-m) 0;
    }
  }

  &__page {
    margin: 0 auto;

    width: 100%;
    max-width: rem(1186);
  }

  &__page-title {
    margin-bottom: rem(70);

    font-size: rem(80);
    line-height: (90/80);
    font-weight: 700;

    @include mmax(768) {
      margin-bottom: rem(38);

      font-size: rem(40);
    }
  }

  &__page-content {

  }

  &__footer {
    margin-top: rem(140);

    padding: 0 rem($padding-hor) rem(20);
    width: 100%;
    pointer-events: none;

    @include mmax(768) {
      margin-top: rem(60);
      padding: 0 rem($padding-hor-m) rem(28);
    }

    &--fullpage {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
