@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.content-area {
  font-size: 1rem;
  line-height: (28/18);

  @include mmax(768) {
    font-size: rem(16);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: em(40, 50);

    font-weight: 700;
    line-height: (60/50);
  }

  h1 {
    font-size: rem(50);

    @include mmax(768) {
      font-size: rem(32);
    }
  }

  h2 {
    font-size: rem(42);
    font-weight: 600;

      @include mmax(768) {
      font-size: rem(28);
    }
  }

  h3 {
    font-size: rem(36);
    font-weight: 600;

      @include mmax(768) {
      font-size: rem(24);
    }
  }

  h4 {
    font-size: rem(28);
    font-weight: 600;

      @include mmax(768) {
      font-size: rem(21);
    }
  }

  h5 {
    font-size: rem(21);
    font-weight: 600;

      @include mmax(768) {
      font-size: rem(18);
    }
  }

  h6 {
    font-size: rem(18);
    font-weight: 600;

      @include mmax(768) {
      font-size: rem(16);
    }
  }

  p, div {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: em(40);
    }
  }

  blockquote {
    position: relative;

    margin: rem(80) 0 0 0;
    padding: rem(60) rem(80);

    background: $c-white;
    border-radius: rem(32);

    @include mmax(768) {
      margin-top: rem(50);
      padding: rem(24) rem(20);

      border-radius: rem(20);
    }

    &:not(:last-child) {
      margin-bottom: em(40);
    }

    &:before {
      content: '';

      position: absolute;
      top: rem(-66 + 19);
      left: rem(81);
      z-index: -1;

      @include size(rem(88), rem(66));
      background: transparent url("../../images/quotes.svg") center center no-repeat;
      background-size: contain;

      @include mmax(768) {
        top: rem(-31 + 10);
        left: rem(28);

        @include size(rem(42), rem(31));

        background: transparent url("../../images/quotes-filled.svg") center center no-repeat;
        background-size: contain;
      }
    }
  }

  ul,
  ol {
    padding-left: em(40);
    margin-bottom: em(40);
  }

  ul {

  }

  ol {

  }

  li {
    &:not(:last-child) {
      margin-bottom: em(12);
    }
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  a {
    @include link {
      font-family: inherit;
      text-decoration: underline;
      color: $c-accent;
    }

    @include link-h {
      text-decoration: none;
    }
  }

  b,
  strong {
    font-family: inherit;
    font-weight: 600;
  }

  i,
  em {
    font-style: italic;
  }

  img {
    float: none !important;

    display: block;
    margin: em(40) 0;

    max-width: 100%;
    width: 100%;
    height: auto !important;
    
    border-radius: rem(32);


    @include mmax(768) {
      border-radius: rem(20);
    }
  }
}