@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.chars-slider {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: $vh100;

  &__cont {
    @include full-rel;
  }

  &__counter {
    position: absolute;
    bottom: rem(60);
    left: rem(70);
    z-index: 10;

    font-size: rem(18);

    color: $c-white;

    b {
      font-weight: 600;
      font-size: rem(32);
    }

    @include mmax(768) {
      display: none;
    }
  }

  &__controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: rem(1186 + (90 + 30) * 2);
    height: rem(240);

    pointer-events: none;

    @include mmax(768) {
      padding: 0 rem(24) rem(60 + 24);
      
      width: 100%;
      height: auto;
    }
  }

  &__control {
    position: relative;
    @include reset-button;
    @include flc;
    @include size(rem(90));

    border-radius: 50%;
    background: $c-white;

    pointer-events: all;

    @include mmax(768) {
      @include size(rem(32));
    }

    &:after {
      content: '';

      position: absolute;
      top: rem(-6);
      bottom: rem(-6);
      left: rem(-6);
      right: rem(-6);

      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      transition: all $time-fast;

      @include mmax(768) {
        display: none;
      }
    }

    &:hover:after {
      transform: scale(1.1);
    }

    svg {
      @include size(rem(24));

      @include mmax(768) {
        @include size(rem(20));
      }
    }
  }
}

.chars-slider-slide {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  overflow: hidden;

  width: 100%;
  height: $vh100;

  &__cont {
    @include full-rel;
  }

  &__bg {
    @include full-abs;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      object-fit: cover;

      width: 100vw;
      height: $vh100;
    }
  }

  &__bg-over {
    @include full-abs;

    background: rgba(#000710, 0.6);
    opacity: 0;

    &:after {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      height: rem(400);

      background: linear-gradient(180deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.4;
    }
  }

  &__fg {
    @include full-abs;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);

    width: 100vw;

    @include flc;
    flex-direction: column;
    align-items: flex-start;
    padding: rem($header-h + 40) rem(376) rem(240);

    color: $c-white;
    opacity: 0;

    @include mmax(768) {
      padding: rem($header-h-m + 40) rem($padding-hor-m) rem(116);
    }
  }

  &__title {
    font-size: rem(80);
    font-weight: bold;
    line-height: (90/80);

    @include mmax(768) {
      font-size: rem(40);
    }
  }

  &__content {
    font-size: rem(24);
    line-height: (30/24);

    @include mmax(768) {
      font-size: rem(20);
    }
  }
}