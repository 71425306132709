@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.apartments-promo-panel {
  position: absolute;
  left: rem(220);
  bottom: 50%;

  display: block;
  padding: rem(24) rem(32) rem(18);

  background: $c-white;

  border-radius: rem(32);

  &__title {
    font-size: rem(24);
    font-weight: 700;
  }

  &__button {
    @include flc;
    justify-content: flex-start;
    margin-top: rem(12);

    width: 100%;

    font-size: rem(16);
    font-weight: 900;
    text-transform: uppercase;

    color: $c-accent;

    svg {
      margin-left: rem(12);

      @include size(rem(36));

      transition: all 300ms;
    }
  }

  &:hover &__button {
    svg {
      transform: translate(25%);
    }
  }

  &__icon {
    margin-top: rem(32);

    img {
      width: rem(120);
      height: auto;
    }
  }
}

.m-apartments-promo-panel {
  position: absolute;
  bottom: rem(16);
  left: rem(16);
  right: rem(16);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(12) rem(16) rem(6);

  background: $c-white;

  border-radius: rem(12);

  &__left {
    display: block;
  }

  &__right {
    display: block;
  }

  &__title {
    font-size: rem(16);
    font-weight: 700;
  }

  &__button {
    @include flc;
    justify-content: flex-start;

    width: 100%;

    font-size: rem(16);
    font-weight: 900;
    text-transform: uppercase;

    color: $c-accent;

    i {
      @include flc;
    }

    svg {
      margin-left: rem(12);

      @include size(rem(36));

      transition: all 300ms;
    }
  }

  &:hover &__button {
    svg {
      transform: translate(25%);
    }
  }

  &__icon {

    img {
      width: rem(80);
      height: auto;
    }
  }
}