@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.google-map {
  @include full-abs;

  &__cont {
    @include full-abs;
    overflow: hidden;
  }

  &__renderer {
    position: absolute !important;
    top: 0;
    bottom: -25px;
    left: 0;
    right: 0;

    & > div {
      background: #000 !important;
    }
  }

  .gm-style-iw {
    padding: rem(12) rem(37) !important;
    background: $c-white !important;
    border-radius: rem(20) !important;
    box-shadow: 0px rem(20) rem(40) rgba(0, 9, 22, 0.2) !important;

    font-size: rem(16);
    color: $c-black;
  }

  .gm-style-iw-c {
    overflow: initial !important;
  }

  .gm-style-iw-d {
    overflow: initial !important;
    padding: 0 !important;
    max-height: none !important;
  }

  .gm-style-iw-t {
    &:after {
      display: none !important;
    }

    button.gm-ui-hover-effect {
      display: none !important;
    }
  }
}

.gm-marker {
  //position: relative;
  //
  //&--hover {
  //  z-index: 2;
  //}
  //
  //&__cont {
  //  position: relative;
  //}
  //
  //&__icon {
  //  position: relative;
  //  @include size(56);
  //  @include flc;
  //
  //  border: 1px solid $c-white;
  //
  //  border-radius: 50%;
  //  background: rgba(255, 255, 255, 0.15);
  //  backdrop-filter: blur(4px);
  //  transition: all $time-fast;
  //
  //  svg {
  //    object-fit: cover;
  //
  //    width: rem(30);
  //    height: rem(30);
  //
  //    @include mmax(768) {
  //      @include size(20)
  //    }
  //
  //    path {
  //      transition: all $time-fast;
  //    }
  //  }
  //
  //  @include mmax(768) {
  //    @include size(38)
  //  }
  //}
  //
  //&--main &__icon {
  //  @include size(80);
  //
  //  background: $c-white;
  //
  //  @include mmax(768) {
  //    @include size(60);
  //  }
  //
  //  svg path {
  //    fill: #38383A;
  //  }
  //
  //  svg {
  //    @include size(45);
  //
  //    @include mmax(768) {
  //      @include size(35);
  //    }
  //  }
  //}
  //
  //&--hover &__icon {
  //  background: $c-white;
  //
  //  svg path {
  //    fill: #38383A;
  //  }
  //}
  //
  //&__text {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  bottom: 0;
  //
  //  @include flc;
  //  padding-left: rem(10 + 56);
  //  padding-right: rem(10);
  //
  //  font-size: rem(14);
  //
  //  border-radius: rem(56/2);
  //  background: rgba(255, 255, 255, 0.15);
  //  backdrop-filter: blur(4px);
  //  opacity: 0;
  //  transition: all $time-fast;
  //  pointer-events: none;
  //  user-select: none;
  //
  //  @include mmax(768) {
  //    padding-left: rem(5 + 38);
  //    border-radius: rem(38/2);
  //    font-size: rem(10);
  //  }
  //
  //  span {
  //    display: block;
  //    width: max-content;
  //    max-width: rem(360);
  //
  //    @include mmax(768) {
  //      max-width: rem(320 - 38 - 20 - 38);
  //    }
  //  }
  //}
  //
  //&--main &__text {
  //  padding-left: rem(5 + 80);
  //  border-radius: rem(80/2);
  //
  //  @include mmax(768) {
  //    padding-left: rem(5 + 60);
  //    border-radius: rem(60/2);
  //  }
  //}
  //
  //&--hover &__text {
  //  transform: translate(0, 0);
  //  opacity: 1;
  //}
}
