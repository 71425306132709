@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.news-list {
  &__cont {

  }

  &__list {
    margin: 0 rem(1186 - 1033 - 460) 0 0;

    @include mmax(768) {
      margin: 0;
    }
  }

  &__footer {
    @include flc;
    margin-top: rem(100);

    @include mmax(768) {
      margin-top: rem(40);
    }
  }
}

.news-list-el {
  @include list-el {
    margin-bottom: rem(60);

    @include mmax(768) {
      margin-bottom: rem(32);
    }
  }
  
  &__cont {
    @include flc;
    justify-content: flex-start;

    @include mmax(768) {
      display: block;
    }
  }

  &__photo {
    overflow: hidden;
    
    margin-right: rem(40);

    width: rem(1033);
    min-width: rem(1033);

    font-size: 0;
    
    border-radius: rem(32);

    @include mmax(768) {
      width: 100%;
      min-width: 0;
      
      border-radius: rem(20);
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    width: 100%;

    @include mmax(768) {
      margin-top: rem(20);
    }
  }

  &__date {
    font-size: rem(16);

    color: $c-gray;

    @include mmax(768) {
      font-size: rem(14);
    }
  }

  &__title {
    margin: rem(20) 0;

    font-size: rem(24);
    line-height: (30/24);
    font-weight: normal;

    @include mmax(768) {
      margin: rem(12) 0 rem(20);

      font-size: rem(20);
    }
  }

  &__link-cont {

  }

  &__link {
    @include flc;
    justify-content: flex-start;

    font-size: rem(16);
    font-weight: 900;
    text-transform: uppercase;

    color: $c-accent;
    
    i {
      @include flc;
      margin-left: rem(12);

      @include size(rem(60));

      background: $c-white;
      border-radius: 50%;

      @include mmax(768) {
        @include size(rem(50));
      }

      svg {
        @include size(rem(16));

        @include mmax(768) {
          transform: rotate(180deg);
        }
      }
    }
  }
}