@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.tour-scene {
  &__cont {
    position: relative;
    overflow: hidden;
  }

  &__renderer {
    &--labels {
      @include full-abs;
      pointer-events: none;
      display: none;

      @include mmax(768) {
        display: block;
      }
    }
  }
}
