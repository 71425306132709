@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-alpha-over;

  pointer-events: none;

  @include mmax(768) {
    padding: rem($padding-hor-m) rem($padding-hor-m) 0;
    height: rem($header-h-m);
  }

  &__cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: rem(32) rem($padding-hor) 0;

    height: rem($header-h);

    @include mmax(768) {
      position: relative;

      padding: 0;
      height: 100%;
    }
  }

  &__left {
    @include flc;
    justify-content: flex-start;
  }

  &__logo {
    display: block;

    height: rem(90);

    font-size: 0;

    opacity: 1;
    pointer-events: all;
    transition: all 500ms;

    @include mmax(768) {
      height: auto;
    }

    svg {
      width: rem(90);
      height: auto;

      * {
        transition: all 500ms;
      }

      @include mmax(768) {
        width: rem(42);
      }
    }
  }

  &__lang {
    pointer-events: all;
    margin-left: rem(80);

    @include mmax(768) {
      position: fixed;
      right: rem(78);
      z-index: $z-alpha-over;

      pointer-events: none;
      opacity: 0;
      transition: all $time-fast;
    }
  }

  &__right {
    @include flc;
  }

  &__phone {
    @include flc;
    margin-right: rem(82);
    pointer-events: all;

    @include mmax(768) {
      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;
      pointer-events: none;
      transition: all 500ms;
    }
  }

  &__phone-i {
    @include flc;

    @include size(rem(60));
    min-width: rem(60);

    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: all 500ms;

    svg {
      width: rem(20);
      height: auto;

      * {
        transition: all 500ms;
      }
    }

    @include mmax(768) {
      @include size(rem(50));
      min-width: rem(50);
    }
  }

  &__phone-text {
    margin-left: rem(12);
    font-weight: 600;

    color: $c-white;
    transition: all 500ms;

    @include mmax(768) {
      font-size: rem(16);
      white-space: nowrap;
    }
  }

  &__burger {
    position: relative;

    @include reset-button;
    @include size(rem(76));
    min-width: rem(76);

    background: $c-white;
    border-radius: 50%;
    pointer-events: all;

    @include mmax(768) {
      @include size(rem(50));
      min-width: rem(50);
    }
  }

  &__burger-i {
    @include full-abs;
    @include flc;
    transition: all $time-fast;

    svg {
      width: rem(20);
      height: auto;
    }

    &--close {
      opacity: 0;
    }
  }

  &__burger--open &__burger-i {
    &--burger {
      opacity: 0;
    }

    &--close {
      opacity: 1;
    }
  }

  &__center {
    @include full-abs;
    @include flc;
    margin-top: rem(40);
    transition: all $time-fast;

    @include mmax(768) {
      margin: 0 rem(12) 0 auto;

      position: initial;
    }

    .button {
      pointer-events: all;
    }
  }

  &__button-search {
    display: none;
  }
}

.ws--header-dark .header {
  &__logo {
    svg {
      * {
        fill: $c-accent;
      }
    }
  }

  &__phone-i {
    background: $c-white;

    svg {
      * {
        stroke: $c-accent;
      }
    }
  }

  &__phone-text {
    color: $c-black;
  }
}

.ws--menu-open .header {
  &__center {
    opacity: 0;

    .button {
      pointer-events: none;
    }
  }

  &__phone {
    @include mmax(768) {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__phone-i {
    background: transparent;

    svg {
      * {
        stroke: $c-white;
      }
    }
  }

  &__phone-text {
    color: $c-white;
  }

  &__logo {
    svg {
      * {
        fill: $c-white;
      }
    }

    @include mmax(768) {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__lang {
    opacity: 1;
    pointer-events: all;
  }
}

.ws--links-search .header {
  &__button-apartments {
    display: none;
  }

  &__button-search {
    display: flex;
  }
}
