@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.flat-view {
  position: relative;

  height: $vh100;
  width: 100%;

  @include mmax(768) {
    height: auto;
  }

  &__cont {
    @include full-abs;
    @include flc;
    justify-content: space-between;

    padding: rem(160) rem($padding-hor) rem(105);

    @include mmax(768) {
      @include full-reset;

      display: block;
      padding: rem(85) rem($padding-hor-m) rem($padding-hor-m);
    }
  }

  &__info {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    width: rem(362);
    height: 100%;

    @include mmax(768) {
      display: block;

      width: 100%;
    }
  }

  &__info-top {

  }

  &__info-back {
    @include mmax(768) {
      display: none;
    }
  }

  &__info-flat {
    margin-top: rem(20);
  }

  &__info-bottom {
    width: 100%;
  }

  &__info-button {
    width: 100%;
    max-width: none;
  }

  &__info-legend {

  }

  &__plan {
    position: relative;

    width: 100%;
    height: 100%;

    @include flc;

    img {
      @include full-rel;
      object-fit: contain;

      @include mmax(768) {
        width: 100%;
        height: auto;
        max-height: 100vw;
      }
    }
  }

  &__nav {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    width: rem(362);
    height: 100%;

    @include mmax(768) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: rem(10) 0;

      width: 100%;
      height: auto;
    }
  }

  &__nav-compass {
    @include mmax(768) {
      margin-top: rem(20);
    }

    svg {
      @include size(rem(86));
    }
  }

  &__nav-mini {

  }

  &__back {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    @include flc;
    @include size(rem(48));

    border-radius: 50% 0 0 50%;
    box-shadow: 0px rem(8) rem(20) rgba(90, 72, 60, 0.2);
    background: $c-white;

    svg {
      width: rem(20);
      height: auto;
    }
  }

  &__notice {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include flc;
    height: rem(105);

    font-size: rem(14);

    color: $c-gray;

    @include mmax(768) {
      position: initial;

      margin-top: rem(20);
    }
  }

  &__notice-cont {
    max-width: rem(1000);
  }
}
