@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.social-sticky {
  padding: rem(32);

  border-radius: rem(32);
  background: $c-white;
  
  &__content {

  }

  &__social {

  }

  &__social-el {
    @include list-el {
      margin-bottom: rem(12);
    }

    @include size(rem(60));
    @include flc;

    border-radius: 50%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #CBD0D7;
    transition: all $time-fast;
    background: $c-white;


    &:hover {
      background: rgba(#CBD0D7, 0.2);
    }

    svg {
      @include size(rem(20));
    }


    svg {
      * {
        fill: #A5ADB9;
      }
    }
  }
}