@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.emg {
  &__cont {
    position: relative;
    overflow: hidden;
    border-radius: rem(32);

    @include mmax(768) {
      border-radius: rem(20);
    }
  }

  &__slider {
    margin: -2px;

    .swiper-button-prev,
    .swiper-button-next {
      @include reset-button;
      @include flc;
      @include size(rem(60));

      border-radius: 50%;
      background: $c-white;

      pointer-events: all;
      transition: all $time-fast;

      @include mmax(768) {
        bottom: rem(24);
        top: auto;

        @include size(rem(32));
      }

      &:after {
        display: none;
      }

      &:before {
        content: '';

        display: block;
        @include size(rem(24));

        @include mmax(768) {
          @include size(rem(20));
        }
      }

      &.swiper-button-disabled {
        opacity: 0.6;
      }
    }

    .swiper-button-prev {
      top: auto;
      left: auto;
      right: rem(96 + 2);
      bottom: rem(16 + 2);


      @include mmax(768) {
        left: rem(8 + 2);
        bottom: rem(8 + 2);
      }

      &:before {
        background: transparent url('./i/arr-prev.svg') center center no-repeat;
        background-size: contain;
      }
    }

    .swiper-button-next {
      top: auto;
      right: rem(24 + 2);
      bottom: rem(16 + 2);

      @include mmax(768) {
        right: rem(8 + 2);
        bottom: rem(8 + 2);
      }

      &:before {
        background: transparent url('./i/arr-next.svg') center center no-repeat;
        background-size: contain;
      }
    }
  }

  &__slide {
    position: relative;
    @include prop(880, 509);
  }

  &__img {
    @include full-rel;
    @include full-abs;
    object-fit: cover;
  }

  &__slide-over {
    @include full-abs;

    &:after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      height: rem(210);

      background: linear-gradient(180deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.6;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    padding: 0 rem(24) rem(21);

    color: $c-white;
    pointer-events: none;

    @include mmax(768) {
      bottom: rem(3);

      padding: 0;

      text-align: center;
    }
  }

  &__counter {
    @include mmax(768) {
      font-size: rem(14);
    }

    b {
      font-weight: 600;
      font-size: rem(32);

      @include mmax(768) {
        font-size: rem(24);
      }
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-alpha-over + 1;

    opacity: 0;
    pointer-events: none;
    transition: all 500ms;
  }

  &--mo &__modal {
    opacity: 1;
    pointer-events: initial;
  }
}