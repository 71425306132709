@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.news-item {
  &__cont {

  }

  &__photo {
    position: relative;
    overflow: hidden;

    margin-bottom: rem(40);

    width: 100%;
    height: rem(600);

    border-radius: rem(32);

    @include mmax(768) {
      margin-bottom: rem(20);

      height: rem(200);

      border-radius: rem(20);
    }

    img {
      @include full-rel;
      @include full-abs;
      object-fit: cover;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    padding-bottom: rem(20);
    margin-bottom: rem(60);
    border-bottom: 1px solid rgba(#000710, 0.5);

    @include mmax(768) {
      margin-bottom: rem(30);
      padding-bottom: rem(12);
    }
  }

  &__nav--bottom {
    padding: rem(20) 0 0 0;
    border-bottom: none;
    border-top: 1px solid rgba(#000710, 0.5);

    @include mmax(768) {
      margin-bottom: 0;
    }
  }

  &__back {
    @include flc;

    font-size: rem(16);
    font-weight: 900;
    text-transform: uppercase;

    color: $c-accent;

    i {
      @include flc;
      @include size(rem(60));
      margin-right: rem(12);

      background: $c-white;
      border-radius: 50%;

      @include mmax(768) {
        @include size(rem(50));
      }

      svg {
        width: rem(16);
        height: auto;
      }
    }
  }

  &__date {
    @include flc;

    font-size: rem(24);

    @include mmax(768) {
      font-size: rem(20);
    }
  }

  &__title {
    margin-bottom: rem(70);

    font-size: rem(80);
    line-height: (90/80);
    font-weight: 700;

    @include mmax(768) {
      margin-bottom: rem(38);

      font-size: rem(40);
    }
  }

  &__main {
    position: relative;
  }

  &__content {
    width: rem(880);

    @include mmax(768) {
      width: 100%;
    }
  }

  &__content-text {
    iframe {
      width: 100%;
      height: rem(9/16*880);

      @include mmax(768) {
        height: rem(9/16*342);
      }
    }
  }

  &__gallery {
    margin: rem(60) 0;

    @include mmax(768) {
      margin: rem(40) 0;
    }
  }

  &__back {

  }

  &__date {

  }

  &__sidebar {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    width: rem(124);


    @include mmax(768) {
      display: none;
    }
  }
}