@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.htb {
  &__cont {

  }

  &__options {
    margin-top: rem(100);

    @include mmax(768) {
      margin-top: rem(40);
    }
  }

  &__order {
    margin-top: rem(90);

    @include mmax(768) {
      margin-top: rem(60);
    }
  }

  &__info {
    margin-top: rem(100);

    @include mmax(768) {
      margin-top: rem(60);
    }
  }
}

.htb-options {
  margin: 0 rem(-154);
  padding: 0 rem(154) rem(100);

  background: $c-white;
  border-radius: rem(32);

  @include mmax(769) {
    margin: 0 rem(-$padding-hor-m);
    padding: 0 rem($padding-hor-m) rem(40);
  }

  &__cont {

  }

  &__title {
    padding: rem(60) 0 rem(50);
    
    font-size: rem(50);
    font-weight: 700;
    text-align: center;

    @include mmax(768) {
      padding: rem(40) 0 rem(28);

      font-size: rem(32);
    }
  }

  &__items {
    
  }

  &__item {
    position: relative;
    overflow: hidden;

    width: 100%;
    height: rem(500);
    
    border-radius: rem(32);

    @include list-el {
      margin-bottom: rem(40);
    }

    @include mmax(768) {
      height: rem(402);

      border-radius: rem(20);

      @include list-el {
        margin-bottom: rem(32);
      }
    }
  }

  &__item-bg {
    @include full-abs;

    &:after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      height: rem(285);

      background: linear-gradient(180deg, #050C15 0%, rgba(5, 12, 21, 0) 100%);
      opacity: 0.9;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  &__item-img {
    @include full-rel;
    @include full-abs;
    object-fit: cover;
  }

  &__item-fg {
    @include full-abs;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 rem(60) rem(40);
    
    color: $c-white;

    @include mmax(768) {
      padding: 0 rem(24) rem(32);
    }
  }

  &__item-title {
    margin: 0;

    width: 100%;

    font-size: rem(40);
    font-weight: bold;
    line-height: (50/40);

    @include mmax(768) {
      font-size: rem(28);
    }
  }

  &__item-content {
    margin-top: rem(8);

    width: 100%;

    font-size: rem(24);

    @include mmax(768) {
      margin-top: rem(12);

      font-size: rem(16);
    }
  }
}

.htb-order {
  &__cont {

  }

  &__title {
    margin: 0 0 rem(60);

    font-size: rem(50);
    font-weight: 700;
    text-align: center;

    @include mmax(768) {
      margin: 0 0 rem(32);

      font-size: rem(32);
    }
  }

  &__items {
    overflow: hidden;

    margin: 0 rem(-154);

    @include mmax(768) {
      margin: 0 rem(-$padding-hor-m);
      padding: 0 rem($padding-hor-m);
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include list-el {
      margin-bottom: rem(32);
    }
  }

  &__item-number {
    @include flc;
    margin-right: rem(40);

    width: rem(114);
    min-width: rem(114);

    @include mmax(768) {
      justify-content: flex-end;
      margin-right: rem(12);

      width: rem(48);
      min-width: rem(40);
    }
  }

  &__item-number-dot {
    position: relative;

    i {
      position: relative;

      display: block;
      @include size(rem(15));
      min-width: rem(15);

      border-radius: 50%;
      background: #CBD0D7;

      @include mmax(768) {
        @include size(rem(9));
        min-width: rem(9);
      }
    }
  }

  &__item-number.first &__item-number-dot {
    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      display: block;
      @include size(rem(45));

      background: #CBD0D7;
      opacity: 0.4;
      border-radius: 50%;

      @include mmax(768) {
        @include size(rem(25));
      }
    }

    i {
      position: relative;

      border: 1px solid $c-accent;
      background: $c-white;
    }

    b {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);

      height: 10000px;
      width: 1px;

      background: #CBD0D7;
      pointer-events: none;
    }
  }

  &__item-number.last &__item-number-dot {
    b {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);

      width: 100px;
      height: 10000px;

      background: #F7F4F1;
      pointer-events: none;
    }
  }

  &__item-number-val {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(rem(-14), -50%);

    font-size: rem(18);

    color: $c-gray;

    @include mmax(786) {
      transform: translate(rem(-5), -50%);

      font-size: rem(14);
    }
  }

  &__item-number.first &__item-number-val {
    transform: translate(rem(-29), -50%);

    font-weight: 600;
    font-size: rem(18);

    color: $c-black;

    @include mmax(786) {
      transform: translate(rem(-12), -50%);

      font-size: rem(16);
      line-height: 1;
    }
  }

  &__item-main {
    overflow: hidden;
    position: relative;

    @include flc;
    justify-content: space-between;
    border: 1px solid $c-white;

    width: 100%;
    height: rem(156);

    background: $c-white;
    border-radius: rem(32);

    @include mmax(768) {
      display: block;

      height: auto;

      border-radius: rem(20);
    }
  }

  &__item-text {
    @include flc;
    flex-direction: column;
    padding: 0 rem(60);
    
    height: 100%;
    width: 100%;

    @include mmax(768) {
      display: block;
      padding: rem(20);
      
      height: auto;
    }
  }

  &__item-title {
    width: 100%;

    font-size: rem(32);
    font-weight: 600;
    line-height: (36/32);

    @include mmax(768) {
      font-size: rem(24);
    }
  }

  &__item-content {
    margin-top: rem(8);

    width: 100%;

    @include mmax(768) {
      margin-top: rem(12);

      font-size: rem(16);
    }
  }

  &__item-attachment {
    @include flc;
    flex-direction: column;

    width: rem(268);
    min-height: rem(268);
    height: 100%;

    background: #F0ECE9;

    @include mmax(768) {
      width: 100%;
      height: rem(112);
      min-height: 0;
    }
  }

  &__item-attachment-i {
    @include flc;
    @include size(rem(76));

    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #E0D9D1;
    border-radius: 50%;

    @include mmax(768) {
      @include size(rem(60));
    }
    
    svg {
      @include size(rem(24));

      @include mmax(768) {
        @include size(rem(19));
      }
    }
  }

  &__item-attachment-text {
    margin-top: rem(6);
    
    font-size: rem(16);
    
    color: $c-gray;

    @include mmax(768) {
      font-size: rem(14);
    }
  }

  &__item-icon {
    @include flc;
    margin-left: rem(40);

    width: rem(114);
    min-width: rem(114);
    height: rem(156);

    background: $c-white;
    border-radius: rem(32);

    @include mmax(768) {
      display: none;
    }

    img {
      width: rem(44);
      height: auto;
    }
  }
}

.htb-info {
  margin: 0 rem(-154);

  @include mmax(768) {
    margin: 0 rem(-$padding-hor-m);
  }
  
  &__cont {

  }

  &__image {
    object-fit: cover;
    @include size(rem(1497), rem(700));
    
    border-radius: rem(32);

    @include mmax(768) {
      width: 100%;
      height: rem(533);
    }
  }

  &__content {
    position: relative;

    @include flc;
    margin-top: rem(-260);
    padding: 0 rem(154 - 40);

    @include mmax(768) {
      display: block;
      padding: 0 rem($padding-hor-m);
      margin-top: rem(-308+111);
    }
  }

  &__item {
    margin: 0 rem(20);
    padding: rem(60);

    width: 50%;

    background: $c-white;
    border-radius: rem(32);

    @include mmax(768) {
      margin: 0 0 rem(20);
      padding: rem(32) rem(24);

      width: 100%;

      border-radius: rem(20);
    }
  }

  &__item-title {
    font-size: rem(50);
    font-weight: 700;
    line-height: (60/50);

    @include mmax(768) {
      font-size: rem(32);
    }
  }

  &__item-content {
    margin-top: rem(40);

    font-size: rem(18);
    line-height: (28/18);

    @include mmax(768) {
      margin-top: rem(20);

      font-size: rem(16);
    }
  }
}