@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.contacts {
  &__cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__top {
    position: relative;
    z-index: 2;

    margin: 0 auto;
    padding-top: rem($header-h + 80);

    width: rem(1186);
    height: rem(488);

    @include mmax(768) {
      padding: rem($header-h-m + 34) rem($padding-hor-m) 0;

      width: 100%;
      height: rem(345);
    }
  }

  &__title {
    margin-bottom: rem(70);

    font-size: rem(80);
    line-height: (90/80);
    font-weight: 700;

    @include mmax(768) {
      margin-bottom: rem(38);

      font-size: rem(40);
    }
  }

  &__panel {
    position: absolute;
    left: 0;
    right: 0;

    padding: rem(60);

    background: $c-white;
    border-radius: rem(32);

    @include mmax(768) {
      left: rem($padding-hor-m);
      right: rem($padding-hor-m);

      padding: rem(24) rem(20);
      
      border-radius: rem(20);
    }
  }

  &__panel-title {
    padding-bottom: rem(24);
    margin-bottom: rem(40);
    border-bottom: 1px solid #A5ADB9;

    font-size: rem(32);
    line-height: 1;
    font-weight: 600;

    @include mmax(768) {
      margin-bottom: rem(22);

      font-size: rem(24);
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 rem(-24);

    @include mmax(768) {
      display: block;

      margin: 0;
    }
  }

  &__el {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 rem(24);

    max-width: 25%;

    @include mmax(768) {
      margin: 0;
      
      max-width: none;
      
      @include list-el {
        margin-bottom: rem(16);
      }
    }
  }

  &__i {
    margin-right: rem(21);

    @include mmax(768) {
      margin-right: rem(16);
    }
    
    svg {
      margin-top: rem(4);
      width: rem(20);
      height: auto;

      @include mmax(768) {
        margin-top: rem(1);
      }
    }
  }

  &__text {
    @include mmax(768) {
      font-size: rem(16);
    }
    
    a {
      transition: all $time-fast;

      &:hover {
        color: $c-accent;
      }
    }
  }

  &__bottom {
    position: relative;

    width: 100%;
    height: calc(#{$vh100} - #{rem(488)});
    min-height: rem(592);

    background: #e8e8e8;

    @include mmax(768) {
      min-height: rem(700);
    }
  }

  &__map {
    @include full-abs;
  }

  &__sidebar {
    position: fixed;
    z-index: $z-alpha-over + 1;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translate(rem(526-10), 0);

    width: rem(526);

    transition: all 700ms;

    @include mmax(768) {
      transform: translate(calc(100vw - #{rem(10)}), 0);

      width: 100vw;
    }
  }

  &--open &__sidebar {
    transform: translate(0, 0);
  }

  &__sidebar-cont {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    display: block;
    padding: rem(90) rem(80);

    height: 100%;
    width: 100%;

    background: $c-accent;
    color: $c-white;

    @include mmax(768) {
      @include flc;
      align-items: flex-start;
      padding: rem(80) rem($padding-hor-m) rem(80);
    }
  }

  &__sidebar-wrap {
    @include mmax(768) {
      margin: auto;

      width: 100%;
    }
  }
  
  &__sidebar-button {
    position: absolute;
    right: 100%;
    top: 15%;
    transform: translate(rem(10), 0);

    font-size: 0;

    @include mmax(768) {
      top: 50%;
      transform: translate(rem(10), -50%);
    }
    
    svg {
      width: rem(60);
      height: auto;
    }
  }

  &__sidebar-button-over {
    @include full-abs;
    @include flc;
  }

  &__sidebar-button-i {
    @include flc;
    @include size(rem(40));

    border: 1px solid $c-white;
    border-radius: 50%;

    svg {
      width: rem(16);
      height: auto;
    }
  }

  &__sidebar-close {
    display: none;

    @include mmax(768) {
      position: absolute;
      top: rem(16);
      right: rem(16);

      display: block;

      svg {
        width: rem(50);
        height: auto;
      }
    }
  }
}