@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.floor-mini {
  padding-right: rem(32);

  @include mmax(768) {
    padding-right: rem(0);

  }

  &__cont {
    position: relative;

    @include mmax(768) {
      @include flc;
      flex-direction: column;

      width: rem(130);
    }
  }

  &__street {
    font-size: rem(16);

    color: $c-gray;

    @include mmax(768) {
      font-size: rem(10);
      font-weight: 700;
    }

    &--one {
      padding-left: rem(10);

      @include mmax(768) {
        padding: 0;
      }
    }

    &--two {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      transform: rotate(90deg) translate(105%, -90%);
      transform-origin: right center;

      text-align: right;

      @include mmax(768) {
        transform: rotate(90deg) translate(85%, 0%);
      }
    }
  }

  &__plan {
    font-size: 0;
  }

  &__svg {
    width: rem(233);
    height: rem(233);

    @include mmax(768) {
      width: rem(103);
      height: rem(103);
    }
  }
}
