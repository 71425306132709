@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.p404 {
  position: relative;
  @include flc;

  width: 100%;
  height: $vh100;

  background: $c-accent;
  color: $c-white;

  &__cont {
    width: rem(980);

    @include mmax(768) {
      padding: rem(50) rem($padding-hor-m);

      width: 100%;
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(80);

    @include mmax(768) {
      font-size: rem(40);
    }
  }

  &__subtitle {
    margin-top: rem(40);

    font-weight: 700;
    font-size: rem(50);

    @include mmax(768) {
      margin-top: rem(20);

      font-size: rem(32);
    }
  }

  &__text {
    margin-top: rem(16);

    font-size: rem(24);

    @include mmax(768) {
      font-size: rem(20);
    }
  }

  &__button {
    margin-top: rem(32);
  }
}
