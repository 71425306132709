@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.flat-panel {
  padding: rem(28) rem(40);

  width: rem(362);

  box-shadow: 0px rem(10) rem(20) rgba(68, 68, 68, 0.1);
  border-radius: rem(32);
  background: $c-white;

  @include mmax(786) {
    padding: rem(16) rem(24);

    width: 100%;

    border-radius: rem(20);
  }

  &__cont {

  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: rem(15);

    @include mmax(768) {
      margin-bottom: rem(5);
    }
  }

  &__label {
    width: 100%;

    @include mmax(768) {
      font-size: rem(14);
    }
  }

  &__value {
    @include flc;
    justify-content: flex-start;

    width: rem(115);
    min-width: rem(115);

    font-size: rem(24);

    @include mmax(768) {
      font-size: rem(16);
      font-weight: 700;
    }
  }

  &__price {
    padding-top: rem(24);
    border-top: 1px solid #A5ADB9;

    font-size: rem(32);
    font-weight: 600;

    @include mmax(768) {
      margin-top: rem(10);
      padding-top: rem(8);

      font-size: rem(20);
    }
  }
}
