html, body {
  padding: 0;
  margin: 0;

  width: 100%;
  min-width: 320px;

  touch-action: manipulation;
}

html {
  min-width: 320px;

  font-family: $ff-main;
  font-size: $fz-root;
  font-weight: 400;
  letter-spacing: normal;
  line-height: (28 / 18);

  color: $c-black;
  background: #F7F4F1;

  @include mmax(100000) {
    font-size: #{($fz-root / 1920px * 100)}vw;
  }

  //@include mmax(1600) {
  //  font-size: #{($fz-root / 1600px * 100)}vw;
  //}
  //
  //@include mmax(1280) {
  //  font-size: #{($fz-root / 1280px * 100)}vw;
  //}

  @include mmax(768) {
    font-size: #{($fz-root / 576px * 100)}vw;
  }

  @include mmax(576) {
    font-size: #{($fz-root / 375px * 100)}vw;
  }

  //
  //@include mmax(480) {
  //  font-size: #{($fz-root / 320px * 100)}vw;
  //}
}

body {
  font-size: 1rem;

  @include mmax(768) {
    font-size: (20/18) + rem;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img, video {
  @include adapt-img;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

button {
  @include reset-button;
}

a {
  @include link-reset;
}

.ws {
  position: relative;
  overflow-x: hidden;

  &__header {
    transition: all $time-fast;
  }

  &--fullscreen &__header {
    @include mmin(769) {
      opacity: 0;
      pointer-events: none;

      & * {
        pointer-events: none !important;
      }
    }
  }
}

.transition-fade {
  &-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: all 1000ms;
  }

  &-enter-done {
    z-index: auto;
  }

  &-exit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: auto;
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: all 1000ms;
  }

  &-appear {

  }

  &-appear-active {

  }
}

.light-content-area {
  p {
    margin: 1em 0;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}
