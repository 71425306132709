@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.apartments-fp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  padding: rem(32) rem(32);

  width: rem(180);
  height: auto;

  border-radius: rem(32);
  box-shadow: 0 rem(30) rem(60) rgba(22, 0, 0, 0.05);
  background: $c-white;
  transition: all 500ms;

  &__cont {
  
  }

  &__floor {
    font-size: rem(24);
  }

  &__flats {
    font-size: rem(18);

    color: $c-gray;
  }

  &__link {
    @include flc;
    margin-top: rem(22);

    width: 100%;

    font-size: rem(16);
    font-weight: 900;
    text-transform: uppercase;

    color: $c-accent;

    svg {
      margin-left: rem(12);

      @include size(rem(36));
    }
  }
}