@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.apartments {
  position: relative;

  width: 100%;
  height: $vh100;

  &__cont {
    @include full-abs;
  }

  &__main {
    @include full-abs;
  }

  &__svg {
    @include full-rel;
    @include full-abs;
    object-fit: cover;

    @include mmax(768) {
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-59.5%, 0);

      width: auto;
      height: 100%;
    }
  }

  &__selection {
    path {
      transition: all $time-fast;
      cursor: pointer;
    }
  }

  &__text {
    position: absolute;
    bottom: rem(120);
    left: rem($padding-hor);

    width: rem(552);

    font-size: rem(50);
    line-height: (60/50);
    font-weight: 700;

    color: $c-white;
    pointer-events: none;

    @include mmax(768) {
      top: rem(86);
      left: 0;
      right: 0;
      bottom: auto;

      width: auto;

      font-size: rem(20);
      font-weight: normal;
      text-align: center;
    }

    span {
      display: block;

      @include mmax(768) {
        margin: auto;

        max-width: rem(220);
      }
    }
  }
}
