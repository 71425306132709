@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.ff {
  color: $c-white;

  &__cont {

  }

  &__title {
    text-align: center;
    font-size: rem(40);
    line-height: (50/40);
    font-weight: bold;
  }

  &__description {
    margin-top: rem(20);

    text-align: center;

    @include mmax(768) {
      margin-top: rem(14);

      font-size: rem(16);
    }
  }

  &__form {
    @include mmax(768) {
      margin: rem(20) 0 0;
    }
  }

  &__row {
    margin-bottom: rem(24);
  }

  &__input {
    @include flc;

    padding: 0;
    border: none;
    border-bottom: 1px solid $c-white;

    width: 100%;
    height: rem(52);

    color: $c-white;
    background: transparent;

    @include mmax(768) {
      font-size: rem(16);
    }

    &::placeholder {
      color: rgba($c-white, 0.6);
    }
  }

  textarea#{&}__input {
    padding-top: rem(14);

    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: rem(200);
    min-height: rem(52);
  }

  &__input-error {
    margin-top: rem(10);
    font-size: rem(14);
  }

  &__footer {

  }

  &__errors {
    margin-bottom: rem(10);

    @include mmax(768) {
      font-size: rem(16);
    }
  }

  &__actions {
    @include flc;

    @include mmax(768) {
      margin-top: rem(40);
    }
  }

  &__submit {

  }
}