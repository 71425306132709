@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.cd {
  &__cont {
    position: relative;
  }

  &__progress {
    position: absolute;
    top: rem(-170);
    right: rem(-304);

    width: rem(420);

    @include mmax(768) {
      position: static;
      margin: rem(60) 0 rem(80);

      width: 100%;
    }
  }

  &__main {
    position: relative;

    width: rem(880);

    @include mmax(768) {
      margin-left: auto;

      width: rem(296);
    }
  }

  &__timeline {
    position: absolute;
    top: rem(11);
    left: rem(-194);

    @include mmax(768) {
      top: rem(11 + 5);

      left: rem(-31);
    }
  }

  &__list {
    @include mmax(768) {
      margin-left: auto;
    }
  }
}

.cd-list-el {
  overflow: hidden;
  position: relative;
  @include prop(880, 440);

  display: block;
  margin-bottom: rem(32);

  box-shadow: 0 rem(30) rem(60) rgba(22, 0, 0, 0.05);
  border-radius: rem(32);

  @include mmax(768) {
    border-radius: rem(20);
  }

  @include mmax(768) {
    @include prop(296, 200);

    margin-bottom: rem(20);
  }

  &__cont {
    @include full-abs;
  }

  &__bg {
    @include full-abs;

    img {
      @include full-rel;
      @include full-abs;
      object-fit: cover;

      transition: all 900ms;
    }

    &:after {
      content: '';

      position: absolute;
      top: rem(40);
      left: 0;
      right: 0;
      bottom: 0;

      background: linear-gradient(0deg, #000710 0%, rgba(163, 192, 232, 0) 100%);
      opacity: 0.8;
      transition: all 900ms;

      @include mmax(768) {
        top: 50%;
      }
    }
  }

  &:hover &__bg {
    img {
      transform: scale(1.07);
    }

    &:after {
      opacity: 0.5;
    }
  }

  &__fg {
    @include full-abs;
    @include flc;
    align-items: flex-end;
  }

  &__title {
    padding: rem(32) rem(60);

    width: 100%;
    
    font-size: rem(24);
    font-weight: 400;

    color: $c-white;

    @include mmax(768) {
      padding: rem(16) rem(24);

      font-size: rem(20);
      line-height: (26/20);
    }
  }
}

.cd-progress {
  position: relative;

  width: rem(420);

  @include mmax(768) {
    width: 100%;
  }

  &__cont {
    position: relative;

    width: 100%;
  }

  &__bg {
    font-size: 0;

    width: 100%;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__fg {
    @include full-abs;
  }

  &__circle {
    position: absolute;

    @include flc;
    @include size(rem(60));

    font-size: rem(18);
    font-weight: 600;

    color: $c-white;
    background: $c-accent;
    border-radius: 50%;

    &:first-child {
      left: rem(5);
      bottom: rem(292);

      @include mmax(768) {
        left: 0;
        bottom: rem(241);
      }
    }

    &:last-child {
      right: rem(130);
      bottom: rem(411);

      @include mmax(768) {
        right: rem(102);
        bottom: rem(340);
      }
    }
  }
}

.cd-tl {
  &__cont {
    position: relative;
    
    @include flc;
    flex-direction: column;

    width: rem(11);
    height: auto;
    
    &:before {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      left: rem(5);

      width: rem(1);

      background: #CBD0D7;
    }
  }

  &__item {
    position: relative;

    margin-bottom: rem(50);

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';

        @include size(rem(31));

        background: #CBD0D7;
        opacity: 0.4;
        border-radius: 50%;
      }
    }
  }
  
  &__item-dot {
    position: relative;

    @include size(rem(7));

    border-radius: 50%;
    background: #CBD0D7;
  }

  &__item:first-child &__item-dot {
    @include size(rem(11));

    border: 1px solid $c-accent;
    background: $c-white;
  }
  
  &__item-text {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(rem(-10), -50%);

    text-transform: capitalize;
    white-space: nowrap;
    font-size: rem(12);

    color: #A5ADB9;

    @include mmax(768) {
      display: none;
    }
  }

  &__item:first-child &__item-text {
    transform: translate(rem(-17), -50%);
    font-size: rem(16);

    color: #707986;

    @include mmax(768) {
      transform: translate(rem(17), -50%);
      left: 100%;
      right: auto;

      @include flc;
      padding: 0 rem(8);

      height: rem(31);

      font-size: rem(14);
      
      border-radius: rem(15);
      background: $c-white;
    }
  }

  &__item-year:before {
    content: ' ';

    display: inline-block;
    width: 0.2em;
  }
}