@import "../../../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.docs-list {
  &__cont {

  }

  &__el {
    @include list-el {
      margin-bottom: rem(32);
    }
  }
}

.docs-el {
  overflow: hidden;

  border: 1px solid $c-white;

  background: $c-white;
  border-radius: rem(32);

  @include mmax(768) {
    border-radius: rem(20);
  }

  &__cont {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    @include mmax(768) {
      display: block;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: rem(40) rem(60);
    
    width: 100%;
    height: auto;
    min-height: rem(198);

    @include mmax(768) {
      display: block;
      padding: rem(26) rem(20) rem(22);

      min-height: 0;
    }
  }

  &__title {
    margin: 0;

    font-size: rem(24);
    line-height: (30/24);
    font-weight: normal;

    color: $c-black;
    transition: all 500ms;

    @include mmax(768) {
      font-size: rem(20);
    }
  }

  &__cont:hover &__title {
    color: $c-accent;
  }

  &__date {
    color: $c-gray;

    @include mmax(768) {
      margin-top: rem(12);

      font-size: rem(14);
    }
  }

  &__media {
    @include flc;
    flex-direction: column;

    width: rem(268);
    min-width: rem(268);

    background: #F0ECE9;
    transition: all 500ms;
    color: $c-gray;

    @include mmax(768) {
      width: 100%;
      min-width: 0;
      height: rem(152);
    }
  }

  &__cont:hover &__media {
    background: $c-accent;
    color: $c-white;
  }

  &__icon {
    @include flc;
    @include size(rem(76));

    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid #E0D9D1;

    @include mmax(768) {
      @include size(rem(60));
    }

    svg {
      width: rem(24);
      height: auto;
    }
  }

  &__media-info {
    margin-top: rem(18);

    text-transform: uppercase;

    color: inherit;

    @include mmax(768) {
      margin-top: rem(10);

      font-size: rem(14);
    }
  }
}

