@import "../../../node_modules/@creatorum/scss/src/index";

// Fonts
$fz-root: 18px;
$fz-root-m: 16px;
$ff-main: 'Nunito', sans-serif;

// Colors
$c-white: #ffffff;
$c-black: #000710;
$c-accent: #A03033;
$c-gray: #707986;

// z-index
$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

$header-h: 106;
$header-h-m: 50 + 16;
$padding-hor: 60;
$padding-hor-m: 16;

@mixin mmin($width: 0, $height: 0, $orientation: 0, $media: all) {
  $w: $width + px;
  $h: $height + px;

  @if $orientation != 0 {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) and (orientation: $orientation){
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) and (orientation: $orientation){
        @content;
      }
    }
  } @else {
    @if $height == 0 {
      @media #{$media} and (min-width: $w) {
        @content;
      }
    } @else if $width == 0 {
      @media #{$media} and (min-height: $h) {
        @content;
      }
    } @else {
      @media #{$media} and (min-width: $w) and (min-height: $h) {
        @content;
      }
    }
  }
}

.lang {
  &__cont {
    position: relative;

    @include mmax(768) {
      font-size: rem(16);
    }
  }

  &__current {
    @include flc;

    @include size(rem(60));
    min-width: rem(60);

    text-transform: uppercase;
    font-weight: 600;

    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: all 500ms;
    color: $c-white;

    @include mmax(768) {
      @include size(rem(50));
      min-width: rem(50);
    }
  }

  &__list {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    transform: translate(0, rem(5));

    padding: 1px 0;

    background: #FFFFFF;
    box-shadow: 0px rem(20) rem(40) rgba(0, 9, 22, 0.2);
    border-radius: rem(20);
    opacity: 0;
    pointer-events: none;
    transition: all 300ms;
  }

  &--open &__list {
    transform: translate(0, rem(21));

    opacity: 1;
    pointer-events: all;
  }

  &__list-el {
    margin: rem(14) 0;

    text-transform: uppercase;
    text-align: center;

    color: $c-black;

    a {
      display: block;
      width: 100%;
    }
  }
}

.ws--header-dark .lang {
  &__current {
    background: $c-white;
    color: $c-accent;
  }
}
